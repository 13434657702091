<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit
        </v-icon>Add Budget
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="budgetName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Budget Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                  label="Descripion"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quantity"
                  class="field-style"
                  label="Quantity*"
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Total work hours required, eg - 300 Hrs"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="unitCost"
                  class="field-style"
                  type="number"
                  label="Unit Cost*"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Per hour rate, eg - $20"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Start Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minStartDate"
                    @input="$refs.startDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="endDialog"
                  v-model="endDateDialog"
                  :return-value.sync="endDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="End Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.endDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="budgetStatus"
                  class="field-style"
                  :items="statusList"
                  label="Budget Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="highRiskWork"
                  label="HRW (High Risk Work)"
                  hint="Work involves risk"
                  persistent-hint
                  class="hrw-checkbox"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <br>
          <p class="info-text">
            <span class="nb-text">Nb:- Unit will be 'hr' by default</span> <br>
            *Indicates required field
          </p>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="createProjectBudget()"
            >
              <v-icon class="mr-2">
                mdi-plus-circle-outline
              </v-icon>
              Create
            </v-btn>
            <v-btn
              class="mt-2"
              color="#D9D9D9"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';

export default {
 name: 'CreateNewBudget',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      date: '',
      startDateDialog: false,
      endDateDialog: false,
      statusList: [
        {
          name: 'Active',
          id: 'active',
        },
        {
          name: 'Pending',
          id: 'pending',
        },
        {
          name: 'Completed',
          id: 'completed',
        },
      ],
      budgetName: '',
      quantity: null,
      unitCost: null,
      startDate: '',
      endDate: '',
      description: '',
      budgetStatus: 'pending',
      highRiskWork: false,
      minStartDate: moment().format('YYYY-MM-DD'),
    };
  },
computed: {
  minEndDate () {
    return moment(this.startDate).format('YYYY-MM-DD');
  },
},
watch: {
  showModal: function (newval, oldval) {
    if (newval === false) {
      this.$emit('closed');
    }
  },
},
methods: {
  async createProjectBudget () {
    if (this.$refs.form.validate() === false) {
      this.$store.dispatch('alert/onAlert', {
      message: 'Please fill the required fields before proceeding.',
      type: Constants.ALERT_TYPE_ERROR,
    }, { root: true });
      return;
    }
    this.loading = true;
    await this.$store.dispatch('projects/createProjectBudget', {
      details: {
        name: this.budgetName,
        description: this.description,
        quantity: parseInt(this.quantity),
        unit: 'hr',
        unit_cost: parseInt(this.unitCost),
        planned_start_date: this.startDate,
        planned_end_date: this.endDate,
        status: this.budgetStatus,
        high_risk_work: this.highRiskWork,
      },
      projectId: this.$route.params.projectId,
    }).then(response => {
      this.$store.dispatch('alert/onAlert', {
      message: 'New budget item created successfully.',
      type: Constants.ALERT_TYPE_SUCCESS,
    });
      this.loading = false;
      this.$emit('success');
      this.showModal = false;
    }).catch(() => {
      this.loading = false;
    });
  },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
.hrw-checkbox {
  margin-top: -30px;
}
.info-text {
  margin-left: 10px;
}
.nb-text {
  color: #37474F;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}
</style>
