<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-hard-hat-outline
      </v-icon> Project Contracts
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="showSyncButton"
          text
          outlined
          class="sync-btn"
          @click="syncProjectContractsFromPartner()"
        >
          <v-icon
            color="primary"
            size="24"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn-txt"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          v-if="!accessCheck('project_member')"
          color="#000"
          @click="showCreateDialog = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add New
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="4"
            md="2"
            sm="1"
            class="text-right"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getProjectContractsList()"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-file-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>All Contracts</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="11"
          >
            <v-autocomplete
              v-model="selectedMainContract"
              :items="projectMainContractsList"
              item-text="name"
              item-value="id"
              label="Main Contracts*"
              single-line
              append-icon="mdi-file-sign"
              outlined
              dense
              @input="getContractsListByMainContract()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="filteredHeaders"
        :items="projectContracts"
        :search="search"
        disable-sort
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
      >
        <template
          v-slot:item="{ item }"
        >
          <tr>
            <td
              class="more-link"
              @click="showProjectBudgetItems(item.id)"
            >
              {{ item.name }}<span
                v-if="item.database === false"
                class="partner-project"
              >*</span>
            </td>
            <td>
              <font v-if="item.company !== null">
                {{ item.company.name }}
              </font><font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.main_contract == null">
                N/A
              </font><font v-else>
                {{ item.main_contract.name }}
              </font>
            </td>
            <td>
              <font v-if="item.original_budget == null">
                N/A
              </font><font v-else>
                <span class="currency-style">{{ projectDetails.currency }}</span> {{ item.original_budget }}
              </font>
            </td>
            <td>
              <v-chip
                v-if="getActiveContractItemsCount(item, item.budget_items)"
                color="#37474F"
                small
                outlined
                dark
              >
                {{ contractItemCounts[item.id] }}
              </v-chip>
            </td>
            <td>
              <font
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="showProjectBudgetItems(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="editProjectContract(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                :disabled="item.database === false"
                @click="deleteProjectContract(item.id)"
                @click.stop
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-new-contract
      v-if="showCreateDialog"
      @closed="showCreateDialog = false"
      @success="getProjectContractsList"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getProjectContractsFromPartner"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <edit-contract-details
      v-if="showEditDialog"
      :project-contract-id="projectContractId"
      @closed="showEditDialog = false"
      @success="getProjectContractsList"
    />
    <delete-project-contract
      v-if="showDeleteDialog"
      :title="'Delete this project contract ?'"
      :message="'Action will permanently remove the contract from this project, Do you want to continue ?'"
      @closed="showDeleteDialog = false"
      @confirmed="confirmDelete"
    />
  </div>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateNewContract from './CreateNewContract';
  import Constants from 'src/constants';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import EditContractDetails from './EditContractDetails.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'ContractsList',
    components: {
      'centre-spinner': spinner,
      'create-new-contract': CreateNewContract,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
      'edit-contract-details': EditContractDetails,
      'delete-project-contract': DeleteDialog,
    },
    filters: {
      statusFormat (status) {
        if (status === 'active') {
          return 'Active*';
        } else if (status === 'pending') {
          return 'Pending';
        } else if (status === 'completed') {
          return 'Completed*';
        } else {
          return status;
        }
      },
    },
    mixins: [global],
    data () {
      return {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Supplier', value: 'company.name' },
          { text: 'Main Contract', value: 'main_contract.name' },
          { text: 'Original Budget', value: 'original_budget' },
          { text: 'Items', value: 'budget_items' },
          { text: 'Status', value: 'status' },
          { text: 'More', value: 'more' },
          { text: 'Edit', value: 'edit' },
          { text: 'Delete', value: 'delete' },
        ],
        loading: false,
        search: '',
        showCreateDialog: false,
        showProgress: false,
        processAlert: '',
        projectContractId: '',
        showDeleteDialog: false,
        showEditDialog: false,
        selectedMainContract: '',
        contractItemCounts: {},
      };
    },
    computed: {
      projectContracts () {
        return this.$store.getters['projects/getProjectContracts'];
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      showSyncButton () {
        if (this.projectDetails.database === false) {
          return true;
        }
        return false;
      },
      projectMainContractsList () {
        return this.$store.getters['projects/getProjectMainContracts'];
      },
      filteredHeaders () {
      if (this.accessCheck('project_member')) {
        return this.headers.filter((item) => item.text !== 'Edit' && item.text !== 'Delete');
      } else {
        return this.headers;
      }
    },
    },
    async mounted () {
      await this.getProjectContractsList();
      await this.getProjectMainContractsList();
    },
    methods: {
      async getProjectContractsList () {
        this.loading = true;
        this.selectedMainContract = '';
        const projectId = this.$route.params.projectId;
        await this.$store.dispatch('projects/fetchProjectContracts', {
          projectId: projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getProjectMainContractsList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectMainContracts', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      showProjectBudgetItems (projectContractId) {
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/contracts/${projectContractId}/details`);
      },
      getStatusColor (status) {
        if (status === 'active') {
          return '#2E7D32';
        } else if (status === 'pending') {
          return '#C62828';
        } else if (status === 'completed') {
          return '#FF3700';
        } else {
          return '#37474F';
        }
      },
      editProjectContract (id) {
        this.projectContractId = id;
        this.showEditDialog = true;
      },
      syncProjectContractsFromPartner () {
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getProjectContractsFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchProjectContractsFromPartner', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.showProgress = false;
          this.getProjectContractsList();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      deleteProjectContract (id) {
        this.projectContractId = id;
        this.showDeleteDialog = true;
      },
      async confirmDelete () {
        this.loading = true;
        this.showDeleteDialog = false;
        await this.$store.dispatch('projects/deleteProjectContract', {
          projectContractId: this.projectContractId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project contract deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectContractsList();
        }).catch(() => {
          this.loading = false;
        });
      },
      async getContractsListByMainContract () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchContractsByMainContract', {
          projectId: this.$route.params.projectId,
          details: {
            params: {
              mainContractId: this.selectedMainContract,
            },
          },
        });
        this.loading = false;
      },
      getActiveContractItemsCount (contract, contractItems) {
        const activeContractItems = contractItems.filter(item => item.deleted_at === null);
        this.contractItemCounts[contract.id] = activeContractItems.length;
        return true;
      },
    },
  };
</script>

<style scoped>
.title-style{
  color: #37474F;
}
.icon-style{
  color: #37474F;
  font-size: 45px;
}
.v-data-table { background-color: #ECEFF1; }
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.sync-btn-txt {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-right: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.status-style{
  font-weight: bold;
  text-transform: capitalize;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
}
.all-contracts-btn {
  margin-top: 10px;
}
.more-link{
  cursor: pointer;
}
.table-projects {
    border-radius: 0px !important;
    background: #fff;
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-top: none;
  }
  .project-list-card {
    box-shadow: none !important;
  }
  .table-projects tbody tr:nth-of-type(even) {
    background: #fff !important;
  }
  .table-projects tbody tr:nth-of-type(odd):hover {
    background: #fff !important;
  }
  .action-btn {
    background: #fff !important;
  }
  .filter-card {
    border: 1px solid #ccc !important;
    padding: 15px;
  }
  .table-top-border{
    border: 1px solid #ccc;
    border-bottom: none;
    background: #edf6f9;
  }
  .info-text{
    margin-bottom: 0px !important;
  }

</style>
