<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <v-container>
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-file-document-edit
          </v-icon>
          Update Contract
        </h2>
        <br>
        <v-alert
          v-if="disableUpdateButton"
          text
          prominent
          icon="mdi-information-symbol"
          color="#01579B"
          border="left"
          class="authorization-alert"
        >
          EzyBuild does not support editing imported data. Please make changes to your Autodesk account and resync.
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="ProjectContractName"
                  class="field-style"
                  label="Contract Name*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                v-if="disableUpdateButton"
                cols="12"
                md="6"
              >
                <v-select
                  v-model="projectMainContract"
                  class="field-style"
                  :items="projectMainContractsList"
                  item-text="name"
                  item-value="id"
                  label="Main Contract"
                  outlined
                  single-line
                  dense
                />
              </v-col>
              <v-col
                v-if="!disableUpdateButton"
                cols="12"
                md="6"
              >
                <v-select
                  v-model="projectMainContract"
                  class="field-style"
                  :items="filteredProjectMainContractsList"
                  item-text="name"
                  item-value="id"
                  label="Main Contract"
                  outlined
                  single-line
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  label="Description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="supplierCompany"
                  class="field-style"
                  :items="projectCompanyList"
                  item-text="company.name"
                  item-value="company.id"
                  label="Supplier Company*"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Companies in the project companies list will be displayed here"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  label="Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="disableUpdateButton"
              @click="updateProjectContract()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'EditContractDetails',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      projectContractId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        valid: false,
        loading: false,
        statusList: [
          { name: 'Active', id: 'active' },
          { name: 'Pending', id: 'pending' },
          { name: 'Completed', id: 'completed' },
        ],
        ProjectContractName: '',
        projectMainContract: '',
        description: '',
        supplierCompany: '',
        status: '',
        disableUpdateButton: false,
      };
    },
    computed: {
      projectMainContractsList () {
        return this.$store.getters['projects/getProjectMainContracts'];
      },
      projectCompanyList () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
      projectContractDetails () {
        return this.$store.getters['projects/getProjectContractDetails'];
      },
      filteredProjectMainContractsList () {
        const list = this.$store.getters['projects/getProjectMainContracts'];
        const filteredMainContracts = list.filter(item => item.database === true);
        return filteredMainContracts;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectContractDetails();
      await this.getProjectMainContracts();
      await this.getProjectCompaniesList();
      this.loading = false;
    },
    methods: {
      async getProjectMainContracts () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectMainContracts', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getProjectCompaniesList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getProjectContractDetails () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectContractDetails', {
          projectContractId: this.projectContractId,
        }).then(response => {
          this.ProjectContractName = this.projectContractDetails.name;
          this.projectMainContract = this.projectContractDetails.main_contract !== null ? this.projectContractDetails.main_contract.id : null;
          this.description = this.projectContractDetails.description;
          this.supplierCompany = this.projectContractDetails.company !== null ? this.projectContractDetails.company.id : null;
          this.status = this.projectContractDetails.status;
          if (this.projectContractDetails.database === false) {
            this.disableUpdateButton = true;
            this.loading = false;
            return;
          }
          this.disableUpdateButton = false;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      updateProjectContract () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            return;
        }
        this.loading = true;
        this.$store.dispatch('projects/updateProjectContract', {
          projectContractId: this.projectContractId,
          updateDetails: {
            name: this.ProjectContractName,
            main_contract: this.projectMainContract,
            description: this.description,
            supplier_company: this.supplierCompany,
            status: this.status,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project contract updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
          }).catch(() => {
            this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.field-style {
  margin-top: -10px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 30px;
}
</style>
