<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-plus-outline
        </v-icon> Add Tracking Items
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text>
            <p class="my-p-style">
              Add Budget Items for Performance Tracking <br><br>
              <span class="my-p-style-sub">NB:-Add the budget items you need to monitor in order to make sure your project stays on track.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height ml-1 mr-1"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="selectedBudgets"
                class="field-style"
                menu-props="auto"
                :items="budgetItems"
                :rules="[(v) => !!v || 'This field is required']"
                label="Select Budgets"
                dense
                multiple
                outlined
                single-line
                item-value="id"
                item-text="name"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="addTrackingItems"
            >
              Create
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'AddPerformanceTrackingItems',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        isFormValid: false,
        loading: false,
        selectedBudgets: [],
      };
    },
    computed: {
      performanceTrackingItems () {
        return this.$store.getters['projects/getPerformanceTrackingItems'];
      },
      budgetItems () {
        const allProjectBudgetList = this.$store.getters['projects/getProjectBudgets'];
        const filteredBudgets = allProjectBudgetList.filter(i => !this.performanceTrackingItems.some(j => i.id === j.budget.id));
        return filteredBudgets;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      await this.getBudgetItems();
    },
    methods: {
      async getBudgetItems () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectBudgets', {
          projectId: this.$route.params.projectId,
        });
        this.loading = false;
      },
      async addTrackingItems () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            return;
        }
        this.loading = true;
        await this.$store.dispatch('projects/addPerformanceTackingItems', {
          projectId: this.$route.params.projectId,
          details: {
            budgets: this.selectedBudgets,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Tracking items added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
          }).catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #aaa;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
