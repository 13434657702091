<template>
  <v-dialog
    v-model="showModal"
    width="700"
    persistent
  >
    <v-card
      color="#fff"
      class="pa-3"
    >
      <h2 class="title-style pt-3">
        <v-icon
          class="icon-style"
          left
        >
          mdi-import
        </v-icon>Import Schedule Template
      </h2>
      <p>Choose the template and Start date to preview your schedule</p>
      <v-icon
        class="close-btn"
        @click="showModal = false"
      >
        mdi-close
      </v-icon>
      <v-form
        ref="form"
      >
        <v-container fluid>
          <v-row v-if="!waiting">
            <v-col cols="6">
              <v-select
                v-model="templateId"
                :items="templateList"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Choose a Schedule Template"
                @input="getTemplateDetails"
              />
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="startDialog"
                v-model="startDateDialog"
                :return-value.sync="startDate"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Planned Start Date*"
                    :rules="[(v) => !!v || 'This field is required']"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    class="field-style"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  color="#37474F"
                  :min="minStartDate"
                  @input="$refs.startDialog.save(date)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="startDateDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-card-actions v-if="!waiting">
            <v-btn
              class="mt-2"
              color="#000"
              @click="importTemplate()"
            >
              Import
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aeaeae"
              @click="showModal = false"
            >
              cancel
            </v-btn>
          </v-card-actions>
          <div
            v-else
            class="d-flex justify-center"
          >
            <div>
              <img
                :src="require('../../../../assets/crane_dribbble.gif')"
                alt=""
                width="300"
              >
              <p class="mt-0">
                Please wait your schedule is importing ...
              </p>
              <v-progress-linear
                :value="progressValue"
                striped
                color="deep-orange"
              />
            </div>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';
import moment from 'moment';
import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
export default {
    name: 'ImportScheduleTemplate',
    data () {
        return {
            templateId: null,
            startDate: null,
            startDateDialog: false,
            date: null,
            showModal: true,
            minStartDate: moment().format('YYYY-MM-DD'),
            tasks: {
            data: [],
            links: [],
            },
            coloumns: [
            { name: 'wbs', label: 'WBS', min_width: 50, width: 50, template: gantt.getWBSCode, resize: true },
            { name: 'text', label: 'Task name', tree: true, min_width: 180, width: 200, resize: true },
            { name: 'start_date', label: 'Start', align: 'center', width: 100, resize: true },
            {
            name: 'end_date',
            label: 'End',
            align: 'center',
            width: 100,
            resize: true,
            },
            { name: 'duration', align: 'center', width: 80, resize: true },
            ],
            waiting: false,
            progressValue: 0,
        };
    },
    computed: {
        templateList () {
            return this.$store.getters['jobs/getScheduleTemplates'];
        },
        TemplateDetails () {
            return this.$store.getters['jobs/getScheduleTemplateDetails'];
        },
        projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
    },
    watch: {
        showModal: function (newval, oldval) {
            if (newval === false) {
                this.$emit('closed');
            }
        },
    },
    async mounted () {
        await this.getScheduleTemplates();
    },
    methods: {
        async getScheduleTemplates () {
            await this.$store.dispatch('jobs/fetchScheduleTemplates');
        },
        async importTemplate () {
            if (this.$refs.form.validate() === false) {
                this.$store.dispatch('alert/onAlert', {
                message: 'Please fill the required fields before proceeding.',
                type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                return;
            }
            this.waiting = true;
            this.progressValue = 50;
            await this.$store.dispatch('jobs/importScheduleLayout', {
                project_id: this.$route.params.projectId,
                start_date: this.startDate,
                layoutId: this.templateId,
            }).then(response => {
                this.waiting = false;
                this.showModal = false;
                this.progressValue = 100;
                this.$emit('success');
            }).catch(() => {
                this.waiting = false;
            });
            },
            async getTemplateDetails () {
                await this.$store.dispatch('jobs/fetchLayoutDetails', {
                    layoutId: this.templateId,
                });
            },
    },
};
</script>
<style scoped>
.close-btn{
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
