<template>
  <v-dialog
    v-model="showModal"
    max-width="500"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon> Edit Cash Flow
      </h2>
      <v-form ref="form">
        <v-container fluid>
          <v-card-text class=" text-center">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="job"
                  class="field-style"
                  label="Job"
                  disabled
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="type"
                  class="field-style"
                  :items="items"
                  label="Type"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  :items="statusItems"
                  label="Type"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="clientName"
                  class="field-style"
                  label="Client Name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="clientAmount"
                  class="field-style"
                  label="Client Amount"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="paymentDateDialog"
                  v-model="paymentDateDialog"
                  :return-value.sync="paymentDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="paymentDate"
                      label="Payment Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      class="field-style"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="savePaymentdate"
                    scrollable
                    color="#37474F"
                    :min="minDate"
                    :max="maxDate"
                    @input="$refs.paymentDateDialog.save(savePaymentdate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="paymentDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="dueDateDialog"
                  v-model="dueDateDialog"
                  :return-value.sync="dueDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dueDate"
                      label="Due Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      class="field-style"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="saveDueDate"
                    scrollable
                    color="#37474F"
                    :min="minDate"
                    @input="$refs.dueDateDialog.save(saveDueDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dueDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#000"
              @click="update()"
            >
              Update
            </v-btn>
            <v-btn
              color="#ccc"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>
  <script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  export default {
    name: 'EditCashFlow',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY');
    },
  },
    props: {
      editData: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        showModal: true,
        job: null,
        clientName: null,
        clientAmount: null,
        dueDate: null,
        paymentDate: null,
        saveDueDate: null,
        paymentDateDialog: null,
        dueDateDialog: null,
        loading: false,
        savePaymentdate: null,
        type: null,
        status: null,
        statusItems: [{ name: 'Pending', id: 'pending' }, { name: 'Overdue', id: 'overdue' }, { name: 'Paid', id: 'paid' }],
        items: [{ name: 'Initial Payment', id: 'initial-payment' }, { name: 'Hand Over Payment', id: 'hand-over-payment' }, { name: 'Milestone Payment', id: 'milestone-payment' }],
      };
    },
    computed: {
      minDate () {
        return moment(this.editData.job.planned_start_date).format('YYYY-MM-DD');
    },
    maxDate () {
        return moment(this.editData.job.planned_end_date).format('YYYY-MM-DD');
    },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.loadOnMount();
      this.loading = false;
    },
    methods: {
      async loadOnMount () {
        this.loading = true;
         this.job = this.editData.job.name;
         this.clientName = this.editData.cash_flow.client_name;
         this.clientAmount = this.editData.cash_flow.client_amount;
         this.paymentDate = moment(this.editData.cash_flow.payment_date).local().format('YYYY-MM-DD');
         this.dueDate = moment(this.editData.cash_flow.due_date).local().format('YYYY-MM-DD');
         this.type = this.editData.cash_flow.type;
         this.status = this.editData.cash_flow.status;
         this.loading = false;
      },
      async update () {
        this.loading = true;
        await this.$store.dispatch('cashflow/updateCashFlow', {
          id: this.editData.cash_flow.id,
          details: {
            client_name: this.clientName,
            client_amount: this.clientAmount,
            payment_date: this.paymentDate,
            due_date: this.dueDate,
            type: this.type,
            status: this.status,
          },
        }).then(() => {
          this.loading = true;
          this.$store.dispatch('cashflow/fetchCashFlows', {
            projectId: this.$route.params.projectId,
          }).catch(() => {
            this.$emit('closed');
            this.loading = false;
          });
          this.loading = false;
          this.$emit('closed');
        });
        this.loading = false;
        this.$emit('closed');
      },
    },
  };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
    margin-left: 21px;
    margin-bottom: 10px;
  }

  .icon-style {
    color: #37474F;
    font-size: 55px;
    margin-right: 5px;
  }

  .my-container-style {
    padding-top: 24px;
  }

  .hrw-switch {
    margin-top: -20px;
  }

  .field-style {
    margin-top: -10px;
  }

  .v-card__actions {
    padding: 16px;
    padding-top: 0px !important;
  }
  </style>
