<template>
  <v-dialog
    v-model="showModal"
    width="700"
    persistent
  >
    <v-card
      color="#fff"
      class="pa-3"
    >
      <h2 class="title-style pt-3">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil
        </v-icon>Save Template
      </h2>
      <p class="sub-heading-creation">
        Please fill in the required fields marked with an asterisk (*) and click the "save" button to save your template for future use.
      </p>
      <v-form ref="form">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="templateName"
                label="Template Name"
                dense
                outlined
                :rules="[(v) => !!v || 'This field is required']"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="templateType"
                label="Template Type"
                dense
                outlined
                :rules="[(v) => !!v || 'This field is required']"
                :disabled="true"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="saveTemplate()"
            >
              Save Template
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aeaeae"
              @click="showModal = false"
            >
              cancel
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';
export default {
    name: 'CreateScheduleTemplate',
    data () {
        return {
            templateName: '',
            templateType: 'construction',
            date: new Date(),
            showModal: true,
            loading: false,
        };
    },
    watch: {
        showModal: function (newval, oldval) {
            if (newval === false) {
                this.$emit('closed');
            }
        },
    },
    methods: {
        async saveTemplate () {
            if (this.$refs.form.validate() === false) {
                this.$store.dispatch('alert/onAlert', {
                message: 'Please fill the required fields before proceeding.',
                type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                return;
            }
            this.loading = true;
            await this.$store.dispatch('jobs/saveScheduleTemplate', {
                name: this.templateName,
                type: this.templateType,
                date: this.date,
                projectId: this.$route.params.projectId,
            }).then(response => {
                this.$store.dispatch('alert/onAlert', {
                    message: 'Template saved.',
                    type: Constants.ALERT_TYPE_SUCCESS,
                    });
                    this.showModal = false;
            }).catch(() => {
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped>
.title-style {
  color: #37474f;
  margin-top: 30px;
  font-size: 36px;
  font-weight: 600;
  margin-left: 13px;
}
.title-subheading{
  margin-left: 13px;
}
.sub-heading-creation{
  margin-left: 13px;
}
</style>
