<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-send
        </v-icon>Notify
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1">
            <v-radio-group
              v-model="notificationType"
              row
            >
              <v-radio
                v-for="n in radioList"
                :key="n.id"
                :label="n.label"
                :value="n.id"
                color="#37474F"
                @change="getJobs(n.id)"
              />
            </v-radio-group>
            <v-row>
              <v-col
                v-if="notificationType === 'all'"
                cols="12"
                md="12"
              >
                <p>
                  <v-icon>mdi-alert-circle-outline</v-icon>All the project members will notified
                </p>
              </v-col>
              <v-col
                v-if="notificationType === 'parent'"
                cols="12"
                md="12"
              >
                <v-select
                  v-model="selectedMileStone"
                  class="field-style"
                  :items="jobs"
                  item-text="name"
                  item-value="id"
                  label="Select Milestone"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  hint="Required only for type 'Worker'"
                  persistent-hint
                  outlined
                  dense
                  return-object
                />
              </v-col>
              <v-col
                v-if="notificationType === 'custom'"
                cols="12"
                md="12"
              >
                <v-select
                  v-model="selectedJob"
                  multiple
                  class="field-style"
                  :items="jobs"
                  item-text="name"
                  item-value="id"
                  label="Assign Job"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  hint="Required only for type 'Worker'"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="notify()"
            >
              Notify
            </v-btn>
            <v-btn
              class="mt-2"
              color="aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
  name: 'SendNotification',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      notificationType: 'all',
      showModal: true,
      isFormValid: false,
      loading: false,
      radioList: [
        {
          id: 'all',
          label: 'Notify all members',
        },
        {
          id: 'parent',
          label: 'Milestone',
        },
        {
          id: 'custom',
          label: 'Job assign',
        },
      ],
      selectedMileStone: null,
      selectedJob: null,
    };
  },
  computed: {
    jobs () {
      return this.$store.getters['jobs/getJobs'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  methods: {
    async getJobs (id) {
      this.loading = true;
      const projectTd = this.$route.params.projectId;
      if (id === 'parent') {
        await this.$store.dispatch('jobs/fetchJobs', {
          projectId: projectTd,
          params: {
            params: {
              jobType: 'milestone',
            },
          },
        });
      } else if (id === 'custom') {
        await this.$store.dispatch('jobs/fetchJobs', {
          projectId: projectTd,
        });
      }
      this.loading = false;
    },
    async notify () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch(
          'alert/onAlert',
          {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        return;
      }
      this.loading = true;
      const projectTd = this.$route.params.projectId;
      await this.$store
        .dispatch('jobs/sendNotification', {
          projectId: projectTd,
          data: {
            type: this.notificationType,
            parent_id:
              this.notificationType === 'parent'
                ? this.selectedMileStone.external_id
                : null,
            jobs: this.notificationType === 'custom' ? this.selectedJob : null,
          },
        })
        .then(response => {
          this.$store.dispatch(
            'alert/onAlert',
            {
              message: 'Notification send successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            },
            { root: true },
          );
          this.showModal = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474f;
  margin-left: 21px;
}

.icon-style {
  color: #37474f;
  font-size: 55px;
  margin-right: 5px;
}
</style>
