<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-office-building-plus-outline
        </v-icon>Project Company
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1">
            <p class="my-p-style">
              Add New Project Company <br><br>
              <span class="wrk-hrs-sub">NB:- Adding a new project company will allow all it's app users to check-in to the project.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="selectedCompanies"
                class="field-style ml-5 mr-5"
                :items="companiesList"
                :rules="[(v) => !!v || 'This field is required']"
                item-text="name"
                item-value="id"
                label="Company*"
                single-line
                append-icon="mdi-office-building-plus-outline"
                outlined
                multiple
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2 ml-2"
              color="#000"
              @click="addProjectCompany()"
            >
              <v-icon class="mr-2">
                mdi-plus-circle-outline
              </v-icon>
              Add
            </v-btn>
            <v-btn
              class="mt-2"
              color="#D9D9D9"
              @click="showModal = false"
            >
              <span class="black--text"> cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'AddProjectCompany',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        loading: false,
        valid: false,
        selectedCompanies: [],
      };
    },
    computed: {
      projectCompanies () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
      companiesList () {
        const allCompanies = this.$store.getters['companies/getCompaniesList'];
        const filteredCompanies = allCompanies.filter(i => !this.projectCompanies.some(j => i.id === j.company.id));
        return filteredCompanies;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      await this.getCompaniesList();
    },
    methods: {
      async getCompaniesList () {
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompaniesList');
        this.loading = false;
      },
      async addProjectCompany () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please select atleast one company before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('projects/addProjectCompany', {
          projectId: this.$route.params.projectId,
          details: {
            companies: this.selectedCompanies,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project companies added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },

    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.wrk-hrs-sub {
  font-size: 16px;
  font-weight: bold;
  color: #aaa;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.field-style {
  margin-top: -20px;
}
</style>
