<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-domain
      </v-icon> Project Companies
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="showSyncButton"
          text
          outlined
          class="sync-btn"
          @click="showSyncAlert = true"
        >
          <v-icon
            color="primary"
            size="24"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn-txt"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          color="#000"
          @click="showForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add Company
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="headers"
        :items="projectCompanies"
        :search="search"
        class="elevation-1 table-projects "
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.company.name }}<span
                v-if="item.database === false"
                class="partner-project"
              >*</span>
            </td>
            <td>{{ item.company.trade }}</td>
            <td>
              <span v-if="isCountryAvailable(item.company.address)">
                {{ item.company.address.country }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="projectCompanyAppUsers(item.id)"
              >
                <v-icon>mdi-account-group</v-icon>
              </v-btn>
            </td>
            <td>
              <v-col>
                <v-btn
                  class="mt-n2 action-btn"
                  elevation="1"
                  fab
                  x-small
                  :disabled="item.database === false"
                  icon
                  color="#C62828"
                  @click.stop
                  @click="deleteProjectCompany(item.id, item.company.id)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getProjectCompaniesFromPartner"
    />
    <centre-spinner
      :loading="loading"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <delete-project-company
      v-if="showDelete"
      :title="'Delete this company ?'"
      :message="'Action will permanently remove the company and all its assigned App Users from this project, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <add-project-company
      v-if="showForm"
      @closed="showForm = false"
      @success="getProjectCompanies"
    />
    <project-company-sync-alert
      v-if="showSyncAlert"
      :title="'Sync Project Companies'"
      :message="'Account companies should sync before the project companies, did you sync your account companies to EzyBuild ?'"
      :cancel-button="'No'"
      :confirm-button="'Yes'"
      @closed="routeToCompanies"
      @confirmed="syncProjectCompaniesFromPartner"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import global from 'src/mixins/global';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import AddProjectCompany from './AddProjectCompany.vue';

  export default {
    name: 'CompaniesList',
    components: {
      'centre-spinner': spinner,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
      'delete-project-company': DeleteDialog,
      'add-project-company': AddProjectCompany,
      'project-company-sync-alert': DeleteDialog,
    },
    mixins: [global],
    data () {
      return {
        showForm: false,
        loading: false,
        headers: [
          { text: 'Company Name', align: 'start', value: 'company.name' },
          { text: 'Trade', value: 'trade' },
          { text: 'Country', value: 'country' },
          { text: 'Users', value: 'app_users' },
          { text: 'Delete', value: 'delete' },
        ],
        search: '',
        projectCompanyId: '',
        showProgress: false,
        processAlert: '',
        showDelete: false,
        showSyncAlert: false,
      };
    },
    computed: {
      projectCompanies () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      showSyncButton () {
        if (this.projectDetails.database === false) {
          return true;
        }
        return false;
      },
      projectContracts () {
        return this.$store.getters['projects/getProjectContracts'];
      },
    },
    async mounted () {
     await this.getProjectCompanies();
     await this.getProjectContractsList();
    },
    methods: {
      async getProjectCompanies () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getProjectContractsList () {
        this.loading = true;
        this.selectedMainContract = '';
        const projectId = this.$route.params.projectId;
        await this.$store.dispatch('projects/fetchProjectContracts', {
          projectId: projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      syncProjectCompaniesFromPartner () {
        this.showSyncAlert = false;
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getProjectCompaniesFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchProjectCompaniesFromPartner', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.showProgress = false;
          this.getProjectCompanies();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      deleteProjectCompany (id, companyId) {
        if (this.projectContracts.some(i => i.company !== null && i.company.id === companyId)) {
          this.$store.dispatch('alert/onAlert', {
            message: 'This project company is linked to a contract.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.projectCompanyId = id;
        this.showDelete = true;
      },
      deleteConfirmed () {
        this.loading = true;
        this.showDelete = false;
        this.$store.dispatch('projects/deleteProjectCompany', {
          projectCompanyId: this.projectCompanyId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project company deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectCompanies();
        }).catch(() => {
          this.loading = false;
        });
      },
      isCountryAvailable (address) {
        if (address === null || address.country === null) {
          return false;
        }
        return true;
      },
      projectCompanyAppUsers (companyId) {
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/project-companies/${companyId}/app-users`);
      },
      routeToCompanies () {
        this.showSyncAlert = false;
        this.$router.push({ name: 'Companies List' });
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.sync-btn-txt {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-right: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
  margin-bottom: 0px !important;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
</style>
