<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <v-container>
        <br>
        <h2 class="title-style">
          <v-icon
            class="icon-style"
            left
          >
            mdi-file-document-edit-outline
          </v-icon>Update Main Contract
        </h2>
        <br>
        <v-alert
          v-if="disableUpdateButton"
          text
          prominent
          icon="mdi-information-symbol"
          color="#01579B"
          border="left"
          class="authorization-alert"
        >
          EzyBuild does not support editing imported data. Please make changes to your Autodesk account and resync.
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mainContractName"
                  label="Name*"
                  :rules="[(v) => !!v || 'This feild is required']"
                  class="field-style"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  menu-props="auto"
                  label="Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  label="Description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="ActualDateDialog"
                  v-model="ActualEndDateDialog"
                  :return-value.sync="ActualEndDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="ActualEndDate"
                      label="End Date"
                      class="field-style"
                      prepend-inner-icon="mdi-calendar"
                      hint="Actual completion date"
                      persistent-hint
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="ActualEndDate"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.ActualDateDialog.save(ActualEndDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="ActualEndDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="amountPaid"
                  label="Amount Paid"
                  type="number"
                  class="field-style"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="disableUpdateButton"
              @click="updateProjectMainContract()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';

  export default {
    name: 'EditMainContract',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      mainContractId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        valid: false,
        loading: false,
        statusList: [
          { name: 'Active', id: 'active' },
          { name: 'Pending', id: 'pending' },
          { name: 'Completed', id: 'completed' },
        ],
        minStartDate: moment().format('YYYY-MM-DD'),
        startDateDialog: false,
        ActualEndDateDialog: false,
        mainContractName: '',
        status: '',
        description: '',
        ActualEndDate: null,
        amountPaid: null,
        disableUpdateButton: false,
      };
    },
    computed: {
      mainContractDetails () {
        return this.$store.getters['projects/getProjectMainContractDetails'];
      },
      minEndDate () {
        return moment(this.startDate).format('YYYY-MM-DD');
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.getMainContractDetails();
    },
    methods: {
      async getMainContractDetails () {
        this.loading = true;
        this.$store.dispatch('projects/fetchProjectMainContractDetails', {
          mainContractId: this.mainContractId,
        }).then(response => {
          this.mainContractName = this.mainContractDetails.name;
          this.status = this.mainContractDetails.status;
          this.description = this.mainContractDetails.description;
          this.ActualEndDate = this.mainContractDetails.actual_completion_date;
          this.amountPaid = this.mainContractDetails.amount_paid;
          this.loading = false;
          if (this.mainContractDetails.database === false) {
            this.disableUpdateButton = true;
            this.loading = false;
            return;
          }
          this.disableUpdateButton = false;
          this.loading = false;
        }).catch(() => {
            this.loading = false;
        });
      },
      updateProjectMainContract () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            return;
        }
        this.loading = true;
        this.$store.dispatch('projects/updateProjectMainContract', {
          mainContractId: this.mainContractId,
          updateDetails: {
            name: this.mainContractName,
            status: this.status,
            description: this.description,
            actual_completion_date: this.ActualEndDate,
            amount_paid: this.amountPaid,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project main contract updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.field-style {
  margin-top: -10px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 30px;
}
</style>
