<template>
  <v-dialog
    v-model="showModal"
    max-width="400"
  >
    <v-card
      class="my-card-style"
      color="#fff"
    >
      <v-toolbar
        color="#fff"
      >
        <v-toolbar-title>
          Budget Details
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div class="details-div">
          <p class="my-p-style">
            Budget Name:- <font class="font-my-style">
              {{ budgetName }}
            </font>
          </p>
          <p
            class="my-p-style"
          >
            Planned Start Date:- <font
              v-if="plannedStartDate !== null"
              class="font-my-style"
            >
              {{ plannedStartDate | convertToLocal }}
            </font>
            <font
              v-else
              class="font-my-style"
            >
              N/A
            </font>
          </p>
          <p
            class="my-p-style"
          >
            Planned End Date:- <font
              v-if="plannedEndDate !== null"
              class="font-my-style"
            >
              {{ plannedEndDate | convertToLocal }}
            </font>
            <font
              v-else
              class="font-my-style"
            >
              N/A
            </font>
          </p>
          <p class="my-p-style">
            Projected Work Hours:- <font class="font-my-style">
              {{ projectedWorkHours }} Hrs
            </font>
          </p>
          <p class="my-p-style">
            Time Sheet Hours:- <font class="font-my-style">
              {{ timeSheetHours }} Hrs
            </font>
          </p>
          <p class="my-p-style">
            Budget Progress:- <font class="font-my-style">
              <span class="unit-style"> {{ budgetProgress }} %</span>
            </font>
          </p>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="edit()"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';

export default {
  name: 'BudgetDetails',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY');
    },
  },
  props: {
    budgetId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      showModal: true,
      budgetName: '',
      plannedStartDate: null,
      plannedEndDate: null,
      projectedWorkHours: null,
      timeSheetHours: null,
      budgetProgress: null,
    };
  },
  computed: {
    budgetDetails () {
      return this.$store.getters['projects/getBudgetDetails'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  mounted () {
    this.getBudjectDetails();
  },
  methods: {
    async getBudjectDetails () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchBudgetDetails', {
        budgetId: this.budgetId,
      }).then(response => {
        this.budgetName = this.budgetDetails.name;
        this.plannedStartDate = this.budgetDetails.planned_start_date;
        this.plannedEndDate = this.budgetDetails.planned_end_date;
        this.projectedWorkHours = this.budgetDetails.quantity;
        this.timeSheetHours = this.budgetDetails.time_sheet_hours;
        this.budgetProgress = this.getProgressPercentage(this.budgetDetails.quantity, this.budgetDetails.time_sheet_hours);
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    getProgressPercentage (totalHours, timeSheetHours) {
      const percentage = (timeSheetHours / totalHours) * 100;
      const roundedPercentage = percentage.toFixed(2);
      return roundedPercentage;
    },
    edit () {
      this.$emit('update-budget');
    },
  },
};
</script>
<style scoped>
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.toolbar-header-style {
  color: #37474F;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-toolbar-style {
  color: #37474F;
  background-color: #CFD8DC;
}
.unit-style {
  color: #FF3700;
}
</style>
