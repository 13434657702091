<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col class="text-right table-top-border">
        <v-col>
          <v-badge
            v-if="filtersc !== 0"
            color="red"
            :content="filtersc"
            class="count-badge"
          />
          <v-btn
            color="#000"
            class="mr-3"
            @click="openFilter"
          >
            Filter
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                @click="resetPagination()"
              >
                <v-icon> mdi-filter-off </v-icon>
              </v-btn>
            </template>
            <span>Reset Filter</span>
          </v-tooltip>
        </v-col>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              dense
              @keyup.enter="handleSearch"
              @keydown.esc="handleSearchClear"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allInvoices"
        class="elevation-1 table-jobs"
        disable-sort
        mobile-breakpoint="100"
        :items-per-page="itemsPerPage"
        :server-items-length="totalRecords"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
        :options.sync="options"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.invoice_number }}
            </td>
            <td>
              {{ item.job.name }}
            </td>
            <td>{{ item.from.first_name }} {{ item.from.last_name }}</td>
            <td>
              {{ item.issue_date | convertToLocal }}
            </td>
            <td>
              <span class="currency-color">{{ projectDetails.currency }}</span>
              {{ item.amount }}
            </td>
            <td>
              {{ item.due_date | convertToLocal }}
            </td>
            <td>
              <v-chip
                class="font-my-style"
                outlined
                small
                :color="getStatusColor(item.status)"
              >
                {{ item.status }}
              </v-chip>
            </td>
            <td align="center">
              <v-menu
                v-if="item.status !== 'paid'"
                bottom
                origin="center center"
                transition="scale-transition"
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="transparent"
                    elevation="0"
                    v-on="on"
                  >
                    <v-icon color="black">
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>

                <v-list class="listing-buttons">
                  <v-list-item
                    v-for="action in getActions(item.status)"
                    :key="action.label"
                    link
                    @click="changeInvoiceStatus(item, action.status)"
                  >
                    {{ action.label }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                v-if="item.status === 'paid'"
                color="green"
              >
                mdi-check
              </v-icon>
            </td>
            <td>
              <v-btn
                :class="item.document_file === null ? 'disabled' : ''"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                class="action-btn"
                @click="
                  showDocument(
                    item.document_file.id,
                    item.document_file.original_file_name
                  )
                "
              >
                <v-icon>mdi-download-circle-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showFilterModal"
      max-width="600"
      persistent
    >
      <v-card
        outlined
        color="#fff"
        class="pa-4"
      >
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-calendar-clock
          </v-icon> Filter
          Invoices
        </h2>
        <br>
        <v-select
          v-model="filters.invoiceStatus"
          :items="items"
          item-value="value"
          item-text="text"
          label="Select status"
          outlined
          dense
          multiple
        />
        <v-dialog
          ref="startDialog"
          v-model="startDateDialog"
          :return-value.sync="filters.startDate"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.startDate"
              label="Start Date"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            scrollable
            color="#37474F"
            @input="$refs.startDialog.save(date)"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="startDateDialog = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          ref="endDialog"
          v-model="endDateDialog"
          :return-value.sync="filters.endDate"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.endDate"
              label="End Date"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            scrollable
            color="#37474F"
            @input="$refs.endDialog.save(date)"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="endDateDialog = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="#000"
            depressed
            default
            @click="submitFilter()"
          >
            Filter
          </v-btn>
          <v-btn
            class="mt-2"
            color="#aaa"
            depressed
            default
            @click="cancelFilter()"
          >
            <span class="black--text">Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
export default {
  name: 'InvoiceLists',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY');
    },
  },
  data () {
    return {
      headers: [
        { text: 'Invoice Number', align: 'start', value: 'invoice_number' },
        { text: 'Job', align: 'start', value: 'job_name' },
        { text: 'Issued By', value: 'issued_by' },
        { text: 'Date', value: 'date' },
        { text: 'Amount', value: 'amount' },
        { text: 'Due date', value: 'due' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action', align: 'center' },
        { text: 'Download', value: 'download' },
      ],
      itemsPerPage: 10,
      pageStart: 0,
      loading: false,
      search: '',
      options: {},
      filters: {
        startDate: null,
        endDate: null,
        invoiceStatus: [],
      },
      items: [{ text: 'Accepted', value: 'accepted' }, { text: 'Rejected', value: 'rejected' }, { text: 'Paid', value: 'paid' }, { text: 'Due', value: 'due' }, { text: 'Overdue', value: 'overdue' }],
      showFilterModal: false,
      startDateDialog: false,
      endDateDialog: false,
      date: null,
      filtersc: 0,
    };
  },
  computed: {
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    allInvoices () {
      const list = this.$store.getters['invoices/getInvoices'];
      return list.data;
    },
    paginatedInvoices () {
      return this.$store.getters['invoices/getInvoices'];
    },
    totalRecords () {
      if (Object.keys(this.paginatedInvoices).length > 0) {
        return this.paginatedInvoices.total;
      }
      return 0;
    },
    searchHint () {
      return `Press Enter to filter by Invoice Number: '${this.search}' or press ESC to clear.`;
    },
    projectDetails () {
      return this.$store.getters['projects/getProjectDetails'];
    },
    filterCount () {
      let count = 0;
      Object.values(this.filters).forEach((item) => {
        if (
          item !== null &&
          item !== '' &&
          (!Array.isArray(item) || item.length > 0)
        ) {
          count++;
        }
      });
      return count;
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    this.loading = true;
    await this.getInvoiceList();
    this.loading = false;
  },
  methods: {
    getActions (status) {
      const actions = {
        pending: [
          {
            label: 'Accept',
            status: 'approved',
            icon: 'mdi-check',
            color: 'green',
          },
          {
            label: 'Reject',
            status: 'rejected',
            icon: 'mdi-close',
            color: '#C62828',
          },
        ],
        approved: [
          {
            label: 'Paid',
            status: 'paid',
            icon: 'mdi-currency-usd',
            color: 'green',
          },
          {
            label: 'Unpaid',
            status: 'unpaid',
            icon: 'mdi-currency-usd-off',
            color: '#C62828',
          },
          {
            label: 'Due',
            status: 'overdue',
            icon: 'mdi-calendar-alert',
            color: 'orange',
          },
        ],
        unpaid: [
          {
            label: 'Paid',
            status: 'paid',
            icon: 'mdi-currency-usd',
            color: 'green',
          },
        ],
        overdue: [
          {
            label: 'Paid',
            status: 'paid',
            icon: 'mdi-currency-usd',
            color: 'green',
          },
        ],
        rejected: [
          {
            label: 'Accepted',
            status: 'approved',
            icon: 'mdi-currency-usd',
            color: 'green',
          },
        ],
      };
      return actions[status] || [];
    },
    async changeInvoiceStatus (item, status) {
      await this.$store
        .dispatch('invoices/updateInvoiceStatus', {
          invoiceId: item.id,
          status: status,
        })
        .then((response) => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Request status updated.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getInvoiceList();
        })
        .catch(() => {});
    },
    showDocument (documentTypeId, fileName) {
      this.loading = true;
      this.$store.dispatch('invoices/dowloadDocment', {
        documentId: documentTypeId,
        fileName: fileName,
      });
      this.loading = false;
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      this.pageStart =
        this.options.page * this.itemsPerPage - this.itemsPerPage;
      await this.getInvoiceList();
    },
    async getInvoiceList () {
      this.loading = true;
      await this.$store
        .dispatch('invoices/fetchInvoices', {
          projectId: this.$route.params.projectId,
          params: {
            params: {
              invoiceNumber: this.search === '' ? null : this.search,
              limit: this.itemsPerPage,
              startPage: this.pageStart,
              from: this.filters.startDate,
              to: this.filters.endDate,
              status: this.filters.invoiceStatus,
            },
          },
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async handleSearchClear () {
      this.pageStart = 0;
      this.options.page = 1;
      this.search = null;
      await this.getInvoiceList();
    },
    getStatusColor (val) {
      if (val === 'paid') {
        return 'green';
      } else if (val === 'rejected') {
        return 'red';
      } else if (val === 'overdue') {
        return 'orange';
      } else if (val === 'approved') {
        return 'blue';
      } else {
        return '#9E9E9E ';
      }
    },
    async handleSearch () {
      this.pageStart = 0;
      this.options.page = 1;
      await this.getInvoiceList();
    },
    openFilter () {
      this.showFilterModal = true;
    },
    async submitFilter () {
      await this.paginatedCall();
      this.showFilterModal = false;
      this.filtersc = this.filterCount;
    },
    cancelFilter () {
      this.showFilterModal = false;
      // this.filters.invoiceStatus = [];
      // this.filters.startDate = null;
      // this.filters.endDate = null;
      // this.filtersc = null;
    },
    async resetPagination () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.pageStart = 0;
      this.search = null;
      this.filters.startDate = null;
      this.filters.endDate = null;
      this.filters.invoiceStatus = [];
      this.filtersc = 0;
      await this.getInvoiceList();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}
.v-data-table {
  background-color: #eceff1;
}
.title-style {
  color: #37474f;
}
.icon-style {
  color: #37474f;
  font-size: 45px;
}
.table-jobs {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.status-style {
  font-weight: bold;
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
.info-text {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474f;
  margin-left: 15px;
  margin-top: -20px;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.division-style {
  margin-left: 5px;
  margin-right: 5px;
}
.download-btn {
  background: green !important;
  color: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9 !important;
  cursor: not-allowed !important;
  background-image: none;
  background: #eef1f6 !important;
  /* border-color: #d1dbe5; */
  margin-bottom: 10px;
}
.currency-color {
  color: red;
}
.count-badge {
  z-index: 10;
  position: absolute;
  right: 84px;
  top: 30px;
}
</style>
