<template>
  <div>
    <button
      class="mr-2 mt-3 mb-3 normal_btn"
      :class="!showActive ?'active_btn':''"
      @click="getCashFlow()"
    >
      <v-icon>mdi-cash-multiple</v-icon> Contract Payment
    </button><button
      class="normal_btn"
      :class="showActive ?'active_btn':''"
      @click="getCashFlowGraph()"
    >
      <v-icon>mdi-chart-bar</v-icon> Payment Report
    </button>
    <cash-flow v-if="showCashFlow" />
    <cash-flow-chart v-if="showCashFlowGraph" />
  </div>
</template>

<script>
import CashFlow from './CashFlow.vue';
import CashFlowChart from './CashFlowChart.vue';

export default {
  name: 'CashFlowLandingPage',
  components: {
    'cash-flow': CashFlow,
    'cash-flow-chart': CashFlowChart,
  },
  data: () => ({
    showCashFlowGraph: false,
    selectedItem: -1,
    showCashFlow: true,
    tab: null,
    showActive: false,
  }),
  computed: {
    isMobile () {
      if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted () {
    this.selectedItem = 0;
  },
  methods: {
    getCashFlow () {
      this.showCashFlowGraph = false;
      this.showCashFlow = true;
      this.showActive = false;
    },
    getCashFlowGraph () {
      this.showCashFlowGraph = true;
      this.showCashFlow = false;
      this.showActive = true;
    },
  },
};
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}

.my-back-btn-style {
  margin-bottom: -20px;
}

.premium-style {
  font-size: 25px;
  color: #006883 !important;
  font-weight: bold;
}

.premium-container {
  background-color: #ffffff !important;
  border-radius: 50%;
}

.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.container {
  padding: 0px !important;
}
.active_btn {
    background: none !important;
    background-color: #ffffff !important;
    color: #000;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 2px solid red !important;
    padding: 10px 5px;
}
</style>
