import { render, staticRenderFns } from "./ProjectIssuesList.vue?vue&type=template&id=c4da740a&scoped=true"
import script from "./ProjectIssuesList.vue?vue&type=script&lang=js"
export * from "./ProjectIssuesList.vue?vue&type=script&lang=js"
import style0 from "./ProjectIssuesList.vue?vue&type=style&index=0&id=c4da740a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4da740a",
  null
  
)

export default component.exports