<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <v-container>
        <br>
        <h2 class="title-style">
          <v-icon
            class="icon-style"
            left
          >
            mdi-file-document-edit
          </v-icon>Add Contract
        </h2>
        <br>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="ProjectContractName"
                  label="Name*"
                  :rules="[(v) => !!v || 'This feild is required']"
                  class="field-style"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="projectMainContract"
                  class="field-style"
                  :items="projectMainContractsList"
                  item-text="name"
                  item-value="id"
                  label="Project Main Contract"
                  outlined
                  single-line
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  label="Description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="supplierCompany"
                  class="field-style"
                  :items="projectCompanyList"
                  item-text="company.name"
                  item-value="company.id"
                  label="Supplier Company*"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Companies in the project companies list will be displayed here"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  label="Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="createProjectContract()"
            >
              Create
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'CreateNewContract',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        valid: false,
        loading: false,
        statusList: [
          { name: 'Active', id: 'active' },
          { name: 'Pending', id: 'pending' },
          { name: 'Completed', id: 'completed' },
        ],
        ProjectContractName: '',
        projectMainContract: '',
        description: null,
        supplierCompany: '',
        status: '',
      };
    },
    computed: {
      projectMainContractsList () {
        const list = this.$store.getters['projects/getProjectMainContracts'];
        const filteredMainContracts = list.filter(item => item.database === true);
        return filteredMainContracts;
      },
      projectCompanyList () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
        this.loading = true;
        await this.getProjectMainContracts();
        await this.getProjectCompaniesList();
        this.loading = false;
    },
    methods: {
      async getProjectMainContracts () {
        await this.$store.dispatch('projects/fetchProjectMainContracts', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
      },
      async getProjectCompaniesList () {
        await this.$store.dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
      },
      async createProjectContract () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        const projectId = this.$route.params.projectId;
        await this.$store.dispatch('projects/createProjectContract', {
          projectId: projectId,
          details: {
            name: this.ProjectContractName,
            description: this.description,
            supplier_company: this.supplierCompany,
            main_contract: this.projectMainContract,
            status: this.status,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'New Contract created successfully to this project.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  margin-left: 10px;
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.field-style {
  margin-top: -10px;
}
</style>
