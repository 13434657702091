<template>
  <v-card
    color="#fff"
    class="project-list-card"
  >
    <v-col class="text-right table-top-border" />
    <v-card-title class="filter-card">
      <v-row>
        <v-col
          cols="12"
          lg="4"
          md="5"
          sm="12"
        >
          <v-text-field
            v-model="search"
            label="Search"
            :hint="isSearchAdded ? searchHint : ''"
            append-icon="mdi-magnify"
            outlined
            dense
            @keyup.enter="handleSearch"
            @keydown.esc="handleSearchClear"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="RequestLists"
      class="elevation-1 table-jobs"
      disable-sort
      mobile-breakpoint="100"
      :items-per-page="itemsPerPage"
      :server-items-length="totalRecords"
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
      }"
      :options.sync="options"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.job.name }}</td>
          <td>{{ item.planned_date | convertToLocal }}</td>
          <td>
            <p v-if="item.proposed_date">
              {{ item.proposed_date | convertToLocal }}
            </p>
            <p v-else>
              N/A
            </p>
          </td>
          <td>{{ item.sent_to.first_name }} {{ item.sent_to.last_name }}</td>
          <td>
            <v-icon :color="getPriorityColor(item.job.priority)">
              mdi-circle-medium
            </v-icon>
            {{ item.job.priority }}
          </td>
          <td>
            <v-chip
              :color="getStatusColor(item.status)"
              class="font-my-style"
              outlined
              small
            >
              {{ item.status | statusFormat }}
            </v-chip>
          </td>
          <td>{{ item.created_at | convertToLocal }}</td>
          <td>
            <!-- <v-select
              v-model="status[index]"
              :items="items"
              :item-text="text"
              :item-value="value"
              label="Select any"
              outlined
              dense
              class="mt-2"
              :class="item.proposed_date !== null && item.status === 'pending' ? '': 'disabled'"
            />-->
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
              :offset-x="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  small
                  color="transparent"
                  elevation="0"
                  :disabled="checkStatus(item)"
                  v-on="on"
                >
                  <v-icon color="black">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list class="listing-buttons">
                <v-list-item
                  link
                  @change="changeRequestStatus(item, 'accepted')"
                >
                  <v-icon>mdi-account-check-outline</v-icon>Accept
                </v-list-item>
                <v-list-item
                  link
                  @change="changeRequestStatus(item, 'declined')"
                >
                  <v-icon color="#C62828">
                    mdi-account-remove-outline
                  </v-icon>Decline
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <!-- <td>
            <v-btn
              class="mt-n2 action-btn"
              elevation="1"
              fab
              x-small
              icon
              color="#C62828"
              @click.stop
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </td>-->
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showProposedDate"
      persistent
      max-width="350"
    >
      <v-card
        color="#fff"
        class="pa-5"
      >
        <h3 class="mb-2">
          Select new Proposed Date
        </h3>
        <div class="show-proposed-date-dialog">
          <v-date-picker
            v-model="newProposedDate"
            color="#000"
            class="date-picker-border mb-4"
          />
        </div>
        <v-btn
          class="saveandsendbtn"
          color="#000"
          @click="saveAndSendRequest"
        >
          Save and Send request
        </v-btn>
      </v-card>
    </v-dialog>
    <centre-spinner :loading="loading" />
  </v-card>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
export default {
  name: 'RequestsList',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).format('Do MMMM YYYY');
    },
    statusFormat (val) {
      if (val === 'accepted') {
        return 'Accepted*';
      } else if (val === 'pending') {
        return 'Pending';
      } else {
        return val;
      }
    },
  },
  data () {
    return {
      search: '',
      headers: [
        { text: 'Request Title', align: 'start', value: 'name' },
        { text: 'Planned Start Date', value: 'planned_start_date' },
        { text: 'Proposed Date', value: 'proposed_end_date' },
        { text: 'Assignee', value: 'assignee' },
        { text: 'Priority', value: 'priority' },
        { text: 'Status', value: 'status' },
        { text: 'Added on', value: 'created_at' },
        { text: 'Accept or Reject', value: 'more' },
        // { text: 'Delete', value: 'delete' },
      ],
      itemsPerPage: 10,
      pageStart: 0,
      loading: false,
      options: {},
      items: [
        { text: 'Accept', value: 'accepted' },
        { text: 'Declined', value: 'declined' },
      ],
      status: [],
      showProposedDate: false,
      newProposedDate: null,
      requestJobDetails: [],
    };
  },
  computed: {
    RequestLists () {
      const lists = this.$store.getters['jobs/getJobRequest'];
      return lists.data;
    },
    paginatedJobs () {
      return this.$store.getters['jobs/getJobRequest'];
    },
    totalRecords () {
      if (Object.keys(this.paginatedJobs).length > 0) {
        return this.paginatedJobs.total;
      }
      return 0;
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    this.loading = true;
    await this.getJobRequests();
    this.loading = false;
  },
  methods: {
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      this.pageStart =
        this.options.page * this.itemsPerPage - this.itemsPerPage;
      await this.getJobRequests();
    },
    async getJobRequests () {
      this.loading = true;
      await this.$store
        .dispatch('jobs/fetchJobsRequests', {
          projectId: this.$route.params.projectId,
          params: {
            params: {
              name: this.search === '' ? null : this.search,
              limit: this.itemsPerPage,
              startPage: this.pageStart,
            },
          },
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    getPriorityColor (priorty) {
      if (priorty === 'high') {
        return '#E67E22'; // Pumkin Orange
      } else if (priorty === 'low') {
        return '#2ECC71'; // Emerald Green
      } else if (priorty === 'medium') {
        return '#F1C40F'; // Golden Yellow
      } else {
        return '#C0392B'; // Dark red
      }
    },
    getStatusColor (val) {
      if (val === 'accepted') {
        return '#4CAF50';
      } else if (val === 'pending') {
        return '#FF9800 ';
      } else {
        return '#9E9E9E ';
      }
    },
    async changeRequestStatus (reqid, status) {
      this.requestJobDetails = reqid;
      // const currentStatus = [];
      // currentStatus = this.status.filter(x => x !== null);
      if (status === 'accepted') {
        await this.$store
          .dispatch('jobs/updateRequest', { reqId: reqid, status: status })
          .then(response => {
            this.$emit('success');
            this.$store.dispatch('alert/onAlert', {
              message: 'Request status updated.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.getJobRequests();
            this.$emit('success');
            this.showModal = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        await this.$store
          .dispatch('jobs/updateRequest', { reqId: reqid, status: status })
          .then(() => {
            this.showProposedDate = true;
          });
      }
    },
    async saveAndSendRequest () {
      const jobReq = this.requestJobDetails;
      this.loading = true;
      await this.$store
        .dispatch('jobs/createRequest', {
          jobId: jobReq.job.external_id,
          planned_date: this.newProposedDate,
          sent_to: jobReq.sent_to.app_user_organization_id,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'New Request Sent With Updated date !',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showProposedDate = false;
          this.getJobRequests();
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async handleSearchClear () {
      this.pageStart = 0;
      this.options.page = 1;
      this.search = null;
      await this.getJobRequests();
    },
    async handleSearch () {
      this.pageStart = 0;
      this.options.page = 1;
      await this.getJobRequests();
    },
    checkStatus (item) {
      if (item.proposed_date !== null && item.status === 'pending') {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}

.v-data-table {
  background-color: #eceff1;
}

.title-style {
  color: #37474f;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.table-jobs {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.project-list-card {
  box-shadow: none !important;
  margin-top: 0px;
}

.action-btn {
  background: #fff !important;
}

.status-style {
  font-weight: bold;
}

.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}

.info-text {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474f;
  margin-left: 15px;
  margin-top: -20px;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.division-style {
  margin-left: 5px;
  margin-right: 5px;
}

.show-proposed-date-dialog {
  text-align: center;
}

.date-picker-border {
  border: 1px solid;
}

.saveandsendbtn {
  margin-left: 10px;
}

.v-btn.v-btn--disabled.theme--light {
  background-color: transparent !important;
}
</style>
