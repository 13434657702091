<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col class="text-right table-top-border">
        <v-btn
          color="#000"
          class="mr-3"
          @click="openAddCashFlowForm"
        >
          <v-icon class="pr-1">
            mdi-plus-circle-outline
          </v-icon>
          Add
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="cashFlows"
        class="elevation-1 table-jobs"
        disable-sort
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.job.name }}
            </td>
            <td>
              {{ item.cash_flow.client_name }}
            </td>
            <td>
              {{ item.cash_flow.payment_date | convertToLocal }}
            </td>
            <td v-if="item.estimated_amount !== null">
              <span class="currency-color">{{ item.cash_flow.project.currency }}</span>
              {{ item.estimated_amount }}
            </td>
            <td v-else>
              N/A
            </td>
            <td>
              <span class="currency-color">{{ item.cash_flow.project.currency }}</span>
              {{ item.cash_flow.client_amount }}
            </td>
            <td v-if="item.invoice_amount !== null">
              <span class="currency-color">{{ item.cash_flow.project.currency }}</span>
              {{ item.invoice_amount }}
            </td>
            <td v-else>
              N/A
            </td>
            <td>
              {{ item.cash_flow.due_date | convertToLocal }}
            </td>
            <td>
              <font
                :color="getColor(item.cash_flow.status)"
                class="status-style"
              >
                {{ item.cash_flow.status }}
              </font>
            </td>
            <td>
              {{ item.cash_flow.type | formatType }}
            </td>
            <td>
              <v-btn
                class="mt-n2  action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="openEditForm(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2  action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="confirmDelete(item.cash_flow.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this record?'"
      :message="'Action will permanently remove the  record, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-cash-flow
      v-if="showEditForm"
      :edit-data="editData"
      @closed="showEditForm = false"
    />
    <add-cash-flow
      v-if="showAddCashFlowModal"
      @closed="showAddCashFlowModal = false"
    />
    <centre-spinner :loading="loading" />
  </div>
</template>
<script>
import moment from 'moment';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import EditCashFlow from './EditCashFlow.vue';
import AddCashFlow from './AddCashFlow.vue';
export default {
  name: 'CashFlow',
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY');
    },
    formatType (val) {
      if (val === 'initial-payment') {
        return 'Initial Payment';
      } else if (val === 'milestone-payment') {
        return 'Milestone Payment';
      } else {
        return 'Hand Over Payment';
      }
    },
  },
  components: {
    'delete-app-user': DeleteDialog,
    'centre-spinner': spinner,
    'edit-cash-flow': EditCashFlow,
    'add-cash-flow': AddCashFlow,
  },
  data () {
    return {
      headers: [
        { text: 'Job', align: 'start', value: 'job_name' },
        { text: 'Client', value: 'name' },
        { text: 'Payment Date', value: 'payment_date' },
        { text: 'Estimated Amount', value: 'estimated_amount' },
        { text: 'Client Amount', value: 'client_amount' },
        { text: 'Invoice Amount', value: 'invoice_amount' },
        { text: 'Due Date', value: 'due_date' },
        { text: 'Status', value: 'status' },
        { text: 'Type', value: 'type' },
        { text: 'Edit', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      loading: false,
      showDelete: false,
      showEditForm: false,
      date: null,
      editData: null,
      recordId: null,
      showAddCashFlowModal: false,
    };
  },
  computed: {
    cashFlows () {
      return this.$store.getters['cashflow/getCashFlows'];
    },
  },
  async mounted () {
    await this.getCashFlowList();
  },
  methods: {
    async getCashFlowList () {
      this.loading = true;
      await this.$store.dispatch('cashflow/fetchCashFlows', {
        projectId: this.$route.params.projectId,
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    confirmDelete (id) {
      this.recordId = id;
      this.showDelete = true;
    },
    async deleteConfirmed () {
      await this.$store.dispatch('cashflow/deleteCashFlow', { id: this.recordId });
      this.showDelete = false;
      await this.getCashFlowList();
    },
    openEditForm (item) {
      this.editData = item;
      this.showEditForm = true;
    },
    openAddCashFlowForm () {
      this.showAddCashFlowModal = true;
    },
    getColor (val) {
      if (val === 'paid') {
        return '#2E7D32';
      } else if (val === 'pending') {
        return '#C62828';
      } else {
        return '#FF3700';
      }
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}

.table-jobs {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.project-list-card {
  box-shadow: none !important;
  margin-top: 0px !important;
}

.action-btn {
  background: #fff !important;
}

.status-style {
  font-weight: bold;
  text-transform: capitalize;
}

.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}

.currency-color {
  color: red;
}

.col-6 {
  padding-top: 0px;
}

.currency-color {
  color: red;
}
</style>
