<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon
        class="icon-style"
        left
      >
        mdi-account-multiple-outline
      </v-icon> Project Members
    </h2>
    <v-alert
      v-if="!roleCheck('userAdmin')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        v-if="roleCheck('userAdmin')"
        class="text-right table-top-border"
      >
        <v-btn
          color="#000"
          @click="showForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add Member
        </v-btn>
      </v-col>
      <div v-if="!roleCheck('userAdmin')">
        <br>
        <br>
      </div>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="projectMembers"
        :search="search"
        class="elevation-1 table-projects"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>
              <span v-if="item.phone_number !== null">
                {{ item.phone_number }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.email !== null">
                {{ item.email }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.organization !== null">
                {{ item.organization.name }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <v-chip
                v-if="item.role !== null"
                outlined
                small
                class="ml-2"
                color="#37474F"
                dark
              >
                {{ item.role.name }}
              </v-chip>
              <span v-else>N/A</span>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                :disabled="isAccountUser(item.external_id)"
                @click.stop
                @click="deleteProjectMember(item.external_id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-project-member
      v-if="showDelete"
      :title="'Remove Member ?'"
      :message="'Action will permanently remove the member from this project, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <add-project-member
      v-if="showForm"
      @closed="showForm = false"
      @success="getProjectMembers"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import AddProjectMember from './AddProjectMember.vue';
  import moment from 'moment';

  export default {
    name: 'ProjectMembers',
    components: {
      'centre-spinner': spinner,
      'delete-project-member': DeleteDialog,
      'add-project-member': AddProjectMember,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
    },
    mixins: [global],
    data () {
      return {
        showForm: false,
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'first_name' },
          { text: 'Phone', value: 'phone_number' },
          { text: 'Email', value: 'email' },
          { text: 'Company', value: 'organization.name' },
          { text: 'Role', value: 'role.name' },
          { text: 'Added On', value: 'created_at' },
          { text: 'Remove', value: 'remove' },
        ],
        search: '',
        projectMemberId: '',
        showDelete: false,
      };
    },
    computed: {
      projectMembers () {
        return this.$store.getters['projects/getProjectMembers'];
      },
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
    },
    async mounted () {
     this.roleCheck('userAdmin') && await this.getProjectMembers();
    },
    methods: {
      async getProjectMembers () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectMembers', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      deleteProjectMember (id) {
        this.projectMemberId = id;
        this.showDelete = true;
      },
      deleteConfirmed () {
        this.loading = true;
        this.showDelete = false;
        this.$store.dispatch('projects/deleteProjectMember', {
          projectId: this.$route.params.projectId,
          projectMemberId: this.projectMemberId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project member removed successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectMembers();
        }).catch(() => {
          this.loading = false;
        });
      },
      isCountryAvailable (address) {
        if (address === null || address.country === null) {
          return false;
        }
        return true;
      },
      isAccountUser (externalId) {
        if (this.userProfile.external_id === externalId) {
          return true;
        }
        return false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.sync-btn-txt {
  color: #FF3700;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-top: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
</style>
