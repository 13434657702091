<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-document-outline
      </v-icon> Project Budgets
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="showSyncButton"
          text
          outlined
          class="sync-btn"
          @click="syncBudgetsFromPartner()"
        >
          <v-icon
            color="primary"
            size="24"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn-txt"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          v-if="!accessCheck('project_member')"
          color="#000"
          @click="showCreateDialog = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add Budget
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="filteredHeaders"
        :items="projectBudgets"
        :search="search"
        disable-sort
        class="elevation-1 table-projects "
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showBudgetDetails(item.id)"
            >
              {{ item.name }}<span
                v-if="item.database === false"
                class="partner-project"
              >*</span>
            </td>
            <td>{{ item.quantity }} <span class="hrs-style">Hrs</span></td>
            <td><span class="currency-style">{{ projectDetails.currency }}</span> {{ item.unit_cost }}</td>
            <td><span class="currency-style">{{ projectDetails.currency }}</span> {{ item.original_amount }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="item.planned_start_date !== null"
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.planned_start_date | convertToLocal }}</span>
                  <span
                    v-else
                    v-bind="attrs"
                    v-on="on"
                  >N/A</span>
                </template>
                <span v-if="item.actual_start_date !== null">Actual Start Date <br>{{ item.actual_start_date | convertToLocal }}</span>
                <span v-else>Actual Start Date <br>Not Set</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="item.planned_end_date !== null"
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.planned_end_date | convertToLocal }}</span>
                  <span
                    v-else
                    v-bind="attrs"
                    v-on="on"
                  >N/A</span>
                </template>
                <span v-if="item.actual_end_date !== null">Actual End Date <br>{{ item.actual_end_date | convertToLocal }}</span>
                <span v-else>Actual End Date <br>Not Set</span>
              </v-tooltip>
            </td>
            <td>
              <font
                v-if="item.status !== null"
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font>
              <font
                v-else
              >
                N/A
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showBudgetDetails(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editBudget(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click="deleteBudget(item.id, item.contract_status)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getBudgetsFromPartner"
    />
    <create-new-budget
      v-if="showCreateDialog"
      @closed="showCreateDialog = false"
      @success="getProjectBudgets"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <edit-budget-details
      v-if="showEditDialog"
      :budget-id="budgetId"
      @closed="showEditDialog = false"
      @budget-details-updated="getProjectBudgets"
    />
    <delete-project-budget
      v-if="showDelete"
      :title="'Delete this project budget ?'"
      :message="'Action will permanently remove the budget from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import CreateNewBudget from './CreateNewBudget.vue';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import EditBudgetDetails from './EditBudgetDetails.vue';

  export default {
    name: 'BudgetsList',
    components: {
      'centre-spinner': spinner,
      'partner-auth-check': PartnerAuthCheck,
      'create-new-budget': CreateNewBudget,
      'overlay-alert': OverlayAlert,
      'edit-budget-details': EditBudgetDetails,
      'delete-project-budget': DeleteDialog,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
      statusFormat (val) {
        if (val === 'active') {
          return 'Active*';
        } else if (val === 'completed') {
            return 'Completed*';
        } else if (val === 'pending') {
          return 'Pending';
        } else {
          return val;
        }
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Unit Cost', value: 'unit_cost' },
          { text: 'Budget Amount', value: 'original_amount' },
          { text: 'Planned Start Date', value: 'planned_start_date' },
          { text: 'Planned End Date', value: 'planned_end_date' },
          { text: 'Status', value: 'status' },
          { text: 'More', value: 'more' },
          { text: 'Edit', value: 'edit' },
          { text: 'Delete', value: 'delete' },
        ],
        showProgress: false,
        processAlert: '',
        showCreateDialog: false,
        showEditDialog: false,
        showDelete: false,
        budgetId: '',
      };
    },
    computed: {
      projectBudgets () {
        const list = this.$store.getters['projects/getProjectBudgets'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      showSyncButton () {
        if (this.projectDetails.database === false) {
          return true;
        }
        return false;
      },
      performanceTrackingItems () {
        return this.$store.getters['projects/getPerformanceTrackingItems'];
      },
      filteredHeaders () {
      if (this.accessCheck('project_member')) {
        return this.headers.filter((item) => item.text !== 'Edit' && item.text !== 'Delete');
      } else {
        return this.headers;
      }
    },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectBudgets();
      await this.getPerformanceTrackingItems();
      this.loading = false;
    },
    methods: {
      async getProjectBudgets () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectBudgets', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getPerformanceTrackingItems () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchPerformanceTrackingItems', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      getStatusColor (val) {
        if (val === 'active') {
          return '#2E7D32';
        } else if (val === 'pending') {
          return '#C62828';
        } else if (val === 'completed') {
          return '#FF3700';
        } else {
          return '#37474F';
        }
      },
      syncBudgetsFromPartner () {
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getBudgetsFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchBudgetsFromPartner', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.showProgress = false;
          this.getProjectBudgets();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      editBudget (budgetId) {
        this.showEditDialog = true;
        this.budgetId = budgetId;
      },
      deleteBudget (budgetId, contractStatus) {
        if (contractStatus === true) {
          this.$store.dispatch('alert/onAlert', {
            message: 'This budget is linked to a contract.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (this.performanceTrackingItems.some(item => item.budget.id === budgetId)) {
          this.$store.dispatch('alert/onAlert', {
            message: 'This budget is under performance tracking.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.budgetId = budgetId;
        this.showDelete = true;
      },
      async deleteConfirmed () {
        this.loading = true;
        this.showDelete = false;
        await this.$store.dispatch('projects/deleteProjectBudget', {
          budgetId: this.budgetId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project budget deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showDelete = false;
          this.loading = false;
          this.getProjectBudgets();
        }).catch(() => {
          this.loading = false;
        });
      },
      showBudgetDetails (budgetId) {
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/budgets/${budgetId}/details`);
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.status-style {
  font-weight: bold;
}
.sync-btn-txt {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-right: 10px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
}
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.more-link{
  cursor: pointer;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
